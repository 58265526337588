import React from 'react'
import { graphql } from 'gatsby'
import { object } from 'prop-types'

import { LinkBuilding } from '@jelly/templates'

const Index = ({ data: { props }, pageContext: { author, canonical } }) => {
	// Take the path from thumbnail and assign it to meta.ogImage to fix the issue with social sharing for this template type.
	props.meta.ogImage = props.thumbnail.path
	props.meta.canonical = canonical
	props.canonical = canonical
	props.author = author
	props.author.slug = author.user?.slug || author.slug
	const authorImagePath = author.user?.imagePath.includes('undefined') ? null : author.user?.imagePath
	author.thumbnail = author.thumbnail || author.avatar || authorImagePath
	props.breadcrumbs = props.channels

	return <LinkBuilding {...props} />
}

Index.propTypes = { data: object, pageContext: object }

export default Index

export const GatsbyQuery = graphql`
	query articleLinkBuilding($slug: String!, $preview: Boolean, $skipTOC: Boolean!) {
		# We always need to return props to ve injected on the component
		props: article(slug: $slug, preview: $preview, cache: false) {
			byLine
			docType
			guid
			title
			subtitle
			content
			canonical
			published
			template
			type
			lastModified: displayModified
			parsedContent {
				toc @skip(if: $skipTOC) {
					id
					title
				}
				json
			}
			meta {
				robots
				description
				title
			}
			thumbnail {
				path
				caption
				isBlurred
			}
		}
	}
`
